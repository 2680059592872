import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VAppBar,{attrs:{"dense":"","flat":"","color":"white","fixed":"","height":"60px","elevate-on-scroll":"","app":""}},[_c(VToolbar,{staticClass:"transparent",attrs:{"flat":""}},[_c(VToolbarTitle,[(_vm.$vuetify.breakpoint.mobile)?_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),_vm._v(" Rani Dental clinic")],1)],1),[(!_vm.$vuetify.breakpoint.mobile)?_c(VTabs,{attrs:{"right":""}},[_c(VTabsSlider,{attrs:{"color":"red"}}),_vm._l((_vm.menuItems),function(menu,index){return _c(VTab,{key:index,on:{"click":function($event){return _vm.navigate(menu.link)}}},[_vm._v(_vm._s(menu.title))])})],2):_vm._e()]],2),_c(VNavigationDrawer,{attrs:{"app":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{attrs:{"nav":"","dense":""}},[_c(VListItemGroup,{attrs:{"active-class":"white--text primary"}},_vm._l((_vm.menuItems),function(menu,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.navigate(menu.link)}}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"left":"","x-small":""}},[_vm._v(_vm._s(menu.icon))]),_vm._v(_vm._s(menu.title))],1)],1)}),1)],1),_c(VCard,{staticStyle:{"position":"fixed","bottom":"5px"},attrs:{"flat":"","color":"transparent","width":"100%"}},[_c(VCardText,{staticClass:"text-center"},[_c(VImg,{attrs:{"src":require("@/assets/nav2.png")}}),_c('span',{staticClass:"overline"},[_vm._v(" RANI VISION")]),_vm._v(" "),_c('br'),_vm._v(" We strive improve and enhance your smile! ")],1),_c(VCardText,{staticClass:"text-center"})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }