<template>
  <v-container fluid class="grey lighten-5" id="contact">
    <v-row align="center" justify="center">
      <v-col cols="10">
        <v-row justify="center">
          <v-col cols="12" sm="5">
            <h1 class="font-weight-light display-1">Contact us</h1>

            <h4 class="font-weight-light mt-3">
              RANI Dental Clinic.
              <br />Mikocheni Avacador near TPDF GOLF COURSE <br />P.O Box
              23463, Dar es Salaam. <br />Mobile : +255 757 274 146 <br />Email
              :
              <span class="primary--text">enquiry@ranidentalclinic.co.tz</span>
            </h4>

            <h4 class="font-weight-light mt-3">
              Contact us via the form, We will get back soon.
              <!-- desktop arrow -->
              <v-icon left large v-if="!$vuetify.breakpoint.mobile"
                >fas fa-arrow-circle-right</v-icon
              >
              <!-- mobile arrow -->
              <v-icon v-if="$vuetify.breakpoint.mobile" right
                >fas fa-arrow-circle-down</v-icon
              >
            </h4>
            <h5 class="font-weight-light mt-3">
              <h3 class="font-weight-light">
                Currently
                <span
                  :class="status == 'Opened' ? 'primary--text' : 'red--text'"
                  >{{ status }}</span
                >
              </h3>
              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <span class="primary--text"> Check our Opening Hours</span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row
                      v-for="(work, index) in openingHours"
                      :key="index"
                      :class="work.day == today ? 'primary white--text' : ' '"
                    >
                      <v-col>{{ work.day }}</v-col>
                      <v-col>{{ work.time }}</v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </h5>
          </v-col>
          <v-col cols="12" sm="7">
            <v-alert
              v-model="alert"
              dismissible
              color="primary"
              border="left"
              elevation="2"
              colored-border
              icon="fas fa-paper-plane"
            >
              sdfsfsf<strong>sdf </strong>sfdfd
            </v-alert>
            <v-form ref="form" v-model="valid">
              <v-text-field
                v-model="feedback.name"
                :counter="30"
                :rules="nameRules"
                label="Name"
                color="primary"
                required
              ></v-text-field>
              <v-text-field
                v-model="feedback.email"
                :rules="emailRules"
                label="Email"
                color="primary"
                required
              ></v-text-field>
              <v-textarea
                v-model="feedback.message"
                :rules="textAreaRules"
                :counter="300"
                color="primary"
                label="Message"
                required
              />
              <v-btn
                :disabled="!valid"
                color="primary"
                rounded
                block
                class="mt-3 white--text"
                @click="()=>{sendFeedback(feedback);$refs.form.reset();}"
              >
                Send
              </v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data: () => ({
    openingHours: [
      {
        day: "Monday",
        time: "09:00 - 20:30",
      },
      {
        day: "Tuesday",
        time: "09:00 - 20:30",
      },
      {
        day: "Wednesday",
        time: "09:00 - 20:30",
      },
      {
        day: "Thursday",
        time: "09:00 - 20:30",
      },
      {
        day: "Friday",
        time: "09:00 - 20:30",
      },
      {
        day: "Saturday",
        time: "07:30 - 19:30",
      },
      {
        day: "Sunday",
        time: "14:00 - 16:00",
      },
    ],
    seen: false,
    opacity: 0.8,
    absolute: true,
    overlay: false,
    alert: false,
    icons: ["fa-facebook", "fa-twitter", "fa-linkedin", "fa-instagram"],
    valid: true,

    nameRules: [
      (v) => !!v || "Please don't leave this field empty",
      (v) =>
        (v && v.length >= 6) ||
        "Name should contain atleast 6 characters.(First and Last)",
    ],

    emailRules: [
      (v) => !!v || "Please don't leave this field empty",
      (v) => /.+@.+\..+/.test(v) || "Please enter a valid e-mail",
    ],

    textAreaRules: [(v) => !!v || "Please don't leave this field empty"],
    lazy: false,

    feedback: { name: null, email: null, message: null },
  }),
  methods: {
   ...mapActions(['sendFeedback']),
  },
  computed: {
    status() {
      var time = new Date();
      var currentTime =
        ("0" + time.getHours()).slice(-2) +
        ":" +
        ("0" + time.getMinutes()).slice(-2) +
        ":" +
        ("0" + time.getSeconds()).slice(-2);
        
      switch (time.getDay()) {
        case 0:
          if (currentTime < "16:00:00" && currentTime > "14:00:00")
            return "Opened";
          else return "Closed";

        case 1:
          if (currentTime < "20:30:00" && currentTime > "09:00:00")
            return "Opened";
          else return "Closed";

        case 2:
          if (currentTime < "20:30:00" && currentTime > "09:00:00")
            return "Opened";
          else return "Closed";

        case 3:
          if (currentTime < "20:30:00" && currentTime > "09:00:00")
            return "Opened";
          else return "Closed";

        case 4:
          if (currentTime < "20:30:00" && currentTime > "09:00:00")
            return "Opened";
          else return "Closed";

        case 5:
          if (currentTime < "20:30:00" && currentTime > "09:00:00")
            return "Opened";
          else return "Closed";

        case 6:
          if (currentTime < "19:30:00" && currentTime > "07:30:00")
            return "Opened";
          else return "Closed";

        default:
          return "don't exist";
      }
    },
    today() {
      var today = new Date();
      switch (today.getDay()) {
        case 0:
          return "Sunday";
        case 1:
          return "Monday";
        case 2:
          return "Tuesday";
        case 3:
          return "Wednesday";
        case 4:
          return "Thursday";
        case 5:
          return "Friday";
        case 6:
          return "Saturday";
        default:
          return "don't exist";
      }
    },
  },

};
</script>

<style scoped>
.text-line {
  background-color: transparent;
  color: #eeeeee;
  outline: none;
  outline-style: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid #0d78cf 3px;
  padding: 3px 10px;
}
</style>