<template>
  <div class="grey lighten-5" >
    <div style="text-align:center;">
    <h1>Features</h1>
    <v-icon x-large>mdi-minus</v-icon>
  </div>
  <!-- desktop view  -->
    <v-row id="offer" v-if="!$vuetify.breakpoint.mobile"  class="mx-auto mt-10" >
      <v-col v-for="(service, index) in services" :key="index">
        <v-card width="250" class="mx-auto" flat color="transparent">        
       <v-avatar color="grey" class="mb-9 mx-auto" size="150">
        <v-img :src="require('@/assets/home/services/' + service.img)" width="300" class="mx-auto"/>
       </v-avatar>
          <v-card-text style="color:black;font-size:large;font-weight:500">{{ service.title }}</v-card-text>
          <p v-for="(type, index) in service.types" :key="index" class="text">{{
              type
            }}
          </p>
        </v-card>
      </v-col>
    </v-row>

     <!-- mobile view  -->
     <div id="offer" v-if="$vuetify.breakpoint.mobile">
      <v-card v-for="(service, index) in services" :key="index" width="90%" class="mx-auto mt-15" style="text-align:center" flat color="transparent">        
       <v-avatar color="grey" class="mx-auto" size="100">
        <v-img :src="require('@/assets/home/services/' + service.img)" width="200" class="mx-auto"/>
       </v-avatar>
          <h3 style="text-align:center" class="my-5">{{ service.title }}</h3>
          <p v-for="(type, index) in service.types" :key="index" class="mx-auto" style="line-height:10px">{{
              type
            }}
          </p>
        </v-card>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      services: [
        {
          title: "Orthodontics",
          img: "orthodontic.png",
          types: [
            "Removable Orthodontic Appliance",
            "Mouthgaurd",
            "Habit Breaker & Retainers",
          ],
        },
        {
          title: "Prosthetic Work",
          img: "prosthetic.png",
          types: [
            "Ceramic Crowns/Bridges",
            "Direct/Indirect Veneers",
            "Dentures",
            "Nickel Chrome Crown",
          ],
        },
        {
          title: "Surgical Procedures",
          img: "surgery.png",
          types: [
            "Extraction & Disimpaction",
            "Apicectomy, Intermaxillary Fixation",
            "Cyst Enucleation",
            "Suturing of Soft Tissue Wounds",
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
  .text{
    position: relative;
    bottom: 0;
    line-height: 7px;
    text-align: left;
    font-size: 15px;
  }
  
</style>