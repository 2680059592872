import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import Scroll from 'vuetify/lib/directives/scroll';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c('Nav'),_c(VMain,[_c('vue-page-transition',{attrs:{"name":"fade-in-right"}},[_c('router-view')],1),_c(VBtn,{directives:[{def: Scroll,name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"}],attrs:{"fab":"","dark":"","fixed":"","bottom":"","right":"","color":"primary"},on:{"click":_vm.toTop}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("fa-solid fa-arrow-up")])],1)],1),_c('Contact',{staticClass:"mt-15"}),_c(VOverlay,{attrs:{"opacity":_vm.opacity,"value":_vm.loading}},[_c('loadingAnim')],1),_c('Foo')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }