<template>
  <div>
    <!-- desktop view  -->
    <div  id="top" v-if="!$vuetify.breakpoint.mobile" >
      <v-row>
      <v-col>
        <v-card flat class="welcome_card">
          <v-card-text class="welcome_title">Welcome,<br><br>to <span class="primary--text">Rani Dental Clinic</span></v-card-text>
          <v-card-text class="welcome_text">
          We offer every aspect of dentistry to care for all of your dental needs; <br>  <span class="red--text">Pediatric Dentistry and Special Needs patients (Mentally and physically Challenged patients), Orthodontics for children and adults, as well as General, Oral surgical procedures and Cosmetic Dentistry</span>. This unique situation allows us to care for you and your families from infancy throughout adulthood.
          </v-card-text>
          <v-card-actions>
           
            <v-btn color="primary"  class="ml-14" to="/learnmore" rounded>Learn More<v-icon small right color="white">fa-solid fa-arrow-right</v-icon></v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col class="background">
        <v-img src="@/assets/home/top.svg"  width="600" class="mx-auto dentist_svg"/>
      </v-col>
    </v-row>
    </div>

    <!-- mobile view  -->
    <div  id="top" v-if="$vuetify.breakpoint.mobile">
      <v-card flat class="mx-auto" width="90%">
        <v-img src="@/assets/home/top.svg"  width="250" class="mx-auto mt-16"/>
        <v-card-text class="welcome_title_mobile">Welcome,<br><br>to <span class="primary--text">Rani Dental Clinic</span></v-card-text>
          <v-card-text class="welcome_text_mobile">
          We offer every aspect of dentistry to care for all of your dental needs; <br>  <span class="red--text">Pediatric Dentistry and Special Needs patients (Mentally and physically Challenged patients), Orthodontics for children and adults, as well as General, Oral surgical procedures and Cosmetic Dentistry</span>. This unique situation allows us to care for you and your families from infancy throughout adulthood.
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary"  class="mx-auto" to="/learnmore" rounded>Learn More<v-icon small right color="white">fa-solid fa-arrow-right</v-icon></v-btn>
          </v-card-actions>
        </v-card>
    </div>
   
    
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
  .dentist_svg{
    margin-top:50px
  }
  .background{
    /* background-repeat: no-repeat; */
    /* background: url('@/assets/home/top_bg.png'); */
   
  }
  .welcome_card{
    margin-top:105px
  }
      .welcome_title{
    font-family: "Maven Pro";
     font-size: 45px;
     margin-left: 5%;
  }
    .welcome_text{
    font-family: "Maven Pro";
     font-size: 18px;
     margin-left: 5%;
     line-height: 35px;
     text-align: justify;
  }

  /* mobile css  */
  .welcome_title_mobile{
    font-family: "Maven Pro";
     font-size: 30px;
     line-height: 18px;

  }
    .welcome_text_mobile{
    font-family: "Maven Pro";
     font-size: 18px;
     line-height: 30px;
     text-align: justify;
  }
</style>