<template>
  <v-app>
    <Nav />
    <!-- <v-btn fab
        dark
        v-if="$route.path!='/'"
        left
        right
        color="primary" to="/" class="ml-10 mt-16" >
          <v-icon small>fa-solid fa-arrow-left</v-icon>
        </v-btn> -->
    <v-main>
      <vue-page-transition name="fade-in-right">
        <router-view />
      </vue-page-transition>
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        
        bottom
        right
        color="primary"
        @click="toTop"
      >
        <v-icon small>fa-solid fa-arrow-up</v-icon>
      </v-btn>
    </v-main>
  <Contact class="mt-15"/>
    <v-overlay :opacity="opacity" :value="loading"> <loadingAnim/></v-overlay>
    <Foo/>
  </v-app>
</template>

<script>
import Contact from "./components/home/contact.vue";
import Foo from "@/components/foo.vue"
import Nav from "@/components/nav.vue";
import loadingAnim from '@/components/load.vue';
import { mapGetters } from "vuex";
export default {
  name: "App",
  data: () => ({
    fab: false,
    opacity: 0.8,

  }),
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
  computed:{
    ...mapGetters(['loading']),
  },
  components: {
    Nav,
    loadingAnim,
    Contact,
    Foo,
  },
};
</script>
<style>
@font-face {
  font-family: "Maven Pro";
  src: local("Maven Pro"),
    url(./fonts/Maven_Pro/MavenPro-VariableFont_wght.ttf) format("truetype");
}
</style>