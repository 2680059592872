<template>
  <v-card>
    <v-app-bar dense flat color="white" fixed height="60px" elevate-on-scroll app>
      <v-toolbar flat class="transparent">
        <v-toolbar-title>
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.mobile"
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>
          Rani Dental clinic</v-toolbar-title
        >
      </v-toolbar>
      <!-- desktop  -->
      <template>
        <v-tabs right class="" v-if="!$vuetify.breakpoint.mobile">
          <v-tabs-slider color="red"></v-tabs-slider>
          <v-tab
            v-for="(menu, index) in menuItems"
            :key="index"
            @click="navigate(menu.link)"
            >{{ menu.title }}</v-tab
          >
        </v-tabs>
      </template>

    </v-app-bar>
      <!-- mobile  -->
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list nav dense>
        <v-list-item-group active-class="white--text primary">
          <v-list-item
            v-for="(menu, index) in menuItems"
            :key="index"
            @click="navigate(menu.link)"
          >
            <v-list-item-title
              ><v-icon left x-small>{{ menu.icon }}</v-icon
              >{{ menu.title }}</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-card
        flat
        color="transparent"
        style="position: fixed; bottom: 5px"
        width="100%"
      >
        <v-card-text class="text-center">
          <v-img src="@/assets/nav2.png"></v-img>
          <span class="overline"> RANI VISION</span> <br />
          We strive improve and enhance your smile!
        </v-card-text>
        <v-card-text class="text-center"> </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
      menuItems: [
        {
          title: "Home",
          icon: "fa-solid fa-house",
          link: "#top",
        },
        {
          title: "What we offer?",
          icon: "fa-solid fa-user-doctor",
          link: "#offer",
        },
        {
          title: "Learn more",
          icon: "fa-solid fa-circle-info",
          link: "/learnmore",
        },  {
          title: "Gallery",
          icon: "mdi mdi-image",
          link: "/gallery",
        },
        {
          title: "Team",
          icon: "fa-solid fa-users",
          link: "#team",
        },
        {
          title: "Services",
          icon: "fa-solid fa-list-check",
          link: "#services",
        },
        {
          title: "Contact",
          icon: "fa-solid fa-address-book",
          link: "#contact",
        },
      ],
    };
  },
  methods: {
    navigate(link) {
      if (link.includes("#")) {
        if (this.$route.path === "/") {
          this.$vuetify.goTo(link);
        } else {
          this.$router.push("/");
          setTimeout(() => {
            this.$vuetify.goTo(link);
          }, 500); // Delay in milliseconds
        }
        if (this.$vuetify.breakpoint.mobile) {
          this.drawer = !this.drawer;
        }
      } else {
        this.$router.push(link);
      }
    },
  },
};
</script>

<style scoped>
.nav_img {
  width: 390px;
  height: 90px;
}
.nav_img_mobile {
  width: 200px;
}
</style>