<template>
  <div >
    <!-- desktop  -->
    <div id="services" v-if="!$vuetify.breakpoint.mobile">
      <v-parallax src="https://cdn.vuetifyjs.com/images/parallax/material.jpg">
        <v-row no-gutters
          ><v-col cols="6">
            <v-card flat color="transparent" class="mt-15">
              <v-card-title class="white--text title">Services</v-card-title>
              <v-card-text class="overline white--text">
                <v-row v-for="(service, index) in services1" :key="index">
                  <v-col
                  >
                  <v-btn text class="white--text" :to="service.link"><v-icon x-small left
                      >fa-sharp fa-solid fa-circle-dot</v-icon
                    >{{ service.title }}
                  </v-btn></v-col
                  >

                  <v-col
                  >
                  <v-btn text class="white--text" :to="services2[index].link">
                  <v-icon x-small left
                      >fa-sharp fa-solid fa-circle-dot</v-icon
                    >{{ services2[index].title }}
                  </v-btn></v-col
                  >
                  
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col>
            <v-card color="transparent" height="90%" class="mt-1 mx-auto" flat>
              <v-card-title class="white--text text" >Visit us</v-card-title>
              <v-card-text class="overline white--text"
                >We are here to serve</v-card-text
              >
              <v-card class="" width="550px" height="300px">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.192344959344!2d39.23315241484282!3d-6.746379867842612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c4e0ad65790c7%3A0x28844efe0ba6ab2f!2sRANI%20DENTAL%20CLINIC!5e0!3m2!1sen!2stz!4v1673593849839!5m2!1sen!2stz"
                  width="550px"
                  height="350px"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                >
                </iframe>
              </v-card>
            </v-card>
    
          </v-col>
        </v-row>
      </v-parallax>
    </div>
    <!-- mobile  -->
    <div id="services" v-if="$vuetify.breakpoint.mobile" >
      <v-parallax src="https://cdn.vuetifyjs.com/images/parallax/material.jpg" height="100%">
       <v-card width="80%" height="70%" color="transparent" class="mx-auto" flat>
        <v-card-title class="white--text title">Services</v-card-title>
        <v-card-text class="overline white--text ">
          <v-btn text class="white--text" v-for="(service, index) in services1" 
          
          :key="index" :to="service.link">
          <v-icon 
          x-small 
          left  
         >
          fa-sharp fa-solid fa-circle-dot
          </v-icon>{{ service.title }}
          <br>
        </v-btn>
        <v-btn text class="white--text"  v-for="(service) in services2" 
          
          :key="service.title" :to="service.link">
          <v-icon 
          x-small 
          left  
         >
          fa-sharp fa-solid fa-circle-dot
          </v-icon>{{ service.title }}
          <br>
        </v-btn>
        </v-card-text>
       </v-card>
       <v-card color="transparent" width="95%" class="mx-auto mb-5" flat >
              <v-card-title class="white--text text" >Visit us</v-card-title>
              <v-card-text class="overline white--text"
                >We are here to serve</v-card-text
              >
              <v-card class=" mx-auto" width="100%" height="300px">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.192344959344!2d39.23315241484282!3d-6.746379867842612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c4e0ad65790c7%3A0x28844efe0ba6ab2f!2sRANI%20DENTAL%20CLINIC!5e0!3m2!1sen!2stz!4v1673593849839!5m2!1sen!2stz"
                  width="100%"
                  height="100%"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                >
                </iframe>
              </v-card>
            </v-card>
      </v-parallax>
      
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      services1: [
        {
          title: "Preventive Services",
          show: "",
          link:"/services/preventiveServices"
        },
        {
          title: "Restorations (Fillings)",
          show: "",
          link:"/services/restorations"
        },
        {
          title: "Prosthetic Work",
          show: "",
          link:"/services/prostheticWork"
        },
        {
          title: "Orthodontics",
          show: "",
          link:"/services/orthodontics"
        },
      ],
      services2: [
        {
          title: "Professional Teeth Cleaning",
          show: "",
          link:"/services/professionalTeethCleaning"
        },
        {
          title: "Minor Surgical Procedure",
          show: "",
          link:"/services/minorSurgicalProcedure"
        },
        {
          title: "Endodontic Treatment",
          show: "",
          link:"/services/endodonticTreatment"
        },
        {
          title: "Laser Teeth Whitening",
          show: "",
          link:"/services/laserTeethWhitening"
        },
      ],
    };
  },
};
</script>

<style scoped>
.text {
  font-family: "Maven Pro";
  font-size: 18px;
}
</style>