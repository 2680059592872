<template>
  <div>
  <Top class="top"/>
  <br />
  <Features/>
  <br />
  <Team class="features"/>
  <br>
  <Services/>
  <br>
  </div>
</template>

<script>
import Top from "@/components/home/top.vue";
import Features from "@/components/home/features.vue";
import Team from "@/components/home/team.vue";
import Services from "@/components/home/services.vue";

export default {
  name: "home",

  components: {
    Top,
    Features,
    Team,
    Services
},
};
</script>
<style scoped>
.top{
/* margin-top:110px; */
}
.features{
margin-top:10px;
}
</style>
