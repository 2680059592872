import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/learnmore',
    name: 'learnmore',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/learnmore.vue')
    }
  },
  {
    path: '/services/preventiveServices',
    name: 'preventiveServices',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/preventiveServices.vue')
    }
  },
  {
    path: '/services/restorations',
    name: 'restorations',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/restorations.vue')
    }
  },
  {
    path: '/services/orthodontics',
    name: 'orthodontics',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/orthodontics.vue')
    }
  },
  {
    path: '/services/professionalTeethCleaning',
    name: 'professionalTeethCleaning',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/professionalTeethCleaning.vue')
    }
  },
  {
    path: '/services/minorSurgicalProcedure',
    name: 'minorSurgicalProcedure',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/minorSurgicalProcedure.vue')
    }
  },
  {
    path: '/services/endodonticTreatment',
    name: 'endodonticTreatment',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/endodonticTreatment.vue')
    }
  },
  {
    path: '/services/laserTeethWhitening',
    name: 'laserTeethWhitening',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/laserTeethWhitening.vue')
    }
  },
  {
    path: '/services/prostheticWork',
    name: 'prostheticWork',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/prostheticWork.vue')
    }
  },
  {
    path: '/gallery',
    name: 'gallery',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/gallery.vue')
    }
  },
  
]

const router = new VueRouter({
  routes
})

export default router
