import axios from "axios";

const state = {
  load: false,
};
const getters = {
  loading(state) {
    return state.load;
  },
};
const actions = {
  // get
};
const mutations = {
  loading(state, response) {
    state.load = response;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
