import Vue from "vue";
import Vuex from "vuex";
import feedback from "./modules/feedback";
import loading from "./modules/loading";

import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    loading,
    feedback,
  },
});
