<template>
  <div>
    <div style="text-align: center">
      <h1>Team</h1>
      <v-icon x-large>mdi-minus</v-icon>
    </div>
    <!-- mobile  view -->
    <v-card
      id="team"
      v-if="$vuetify.breakpoint.mobile"
      flat
      color="transparent"
      width="85%"
      class="mx-auto"
    >
      <v-img src="@/assets/home/team/team_bg.svg" width="200px" class="mx-auto" />
      <v-card-text class="welcome_text_mobile"
        >We are often asked when is the best time for a child to see the dentist for the
        first time, we recommend a first visit to the dentist when the child gets their
        first tooth or by their first birthday. We love to see children before problems
        arise, when we can still start them on a path to be cavity free. We don’t want our
        patients to suffer early childhood cavities.</v-card-text
      >

      <v-system-bar lights-out></v-system-bar>
      <v-carousel
        :continuous="false"
        :cycle="cycle"
        :show-arrows="true"
        hide-delimiter-background
        delimiter-icon="mdi-minus"
        height="330"
      >
        <v-carousel-item v-for="(team, index) in team" :key="index">
          <v-sheet height="100%" tile>
            <v-row class="fill-height" align="center" justify="center">
              <div>
                <v-img
                  :src="require('@/assets/home/team/' + team.img)"
                  :lazy-src="require('@/assets/home/team/' + team.img)"
                  class="mx-auto"
                  width="200px"
                  height="200px"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-row> </template
                ></v-img>
                <p class="text-center text-center">
                  <span class="font-weight-bold text-overline">{{ team.name }}</span>
                  <br />
                  <span class="font-weight-medium blue--text text-overline">{{
                    team.role
                  }}</span>
                </p>
              </div>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-card>

    <!-- desktop view -->
    <v-card id="team" v-if="!$vuetify.breakpoint.mobile" flat color="transparent">
      <v-row class="mt-10">
        <v-col class="mx-auto">
          <v-card flat class="ml-15" color="transparent">
            <v-img src="@/assets/home/team/team_bg.svg" width="200px" class="mx-auto" />
            <v-card-text class="welcome_text"
              >We are often asked when is the best time for a child to see the dentist for
              the first time, we recommend a first visit to the dentist when the child
              gets their first tooth or by their first birthday. We love to see children
              before problems arise, when we can still start them on a path to be cavity
              free. We don’t want our patients to suffer early childhood
              cavities.</v-card-text
            >
          </v-card>
        </v-col>
        <v-col>
          <v-system-bar lights-out></v-system-bar>
          <v-carousel
            :continuous="false"
            :cycle="cycle"
            :show-arrows="true"
            hide-delimiter-background
            delimiter-icon="mdi-minus"
            height="400"
          >
            <v-carousel-item v-for="(team, index) in team" :key="index">
              <v-sheet height="100%" tile color="transparent">
                <v-row class="fill-height" align="center" justify="center">
                  <div>
                    <v-img
                      :src="require('@/assets/home/team/' + team.img)"
                      :lazy-src="require('@/assets/home/team/' + team.img)"
                      class="mx-auto"
                      width="350px"
                    >
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                        </v-row> </template
                    ></v-img>
                    <p class="text-center text-center">
                      <span class="font-weight-bold text-overline">{{ team.name }}</span>
                      <br />
                      <span class="font-weight-medium blue--text text-overline">{{
                        team.role
                      }}</span>
                    </p>
                  </div>
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      cycle: false,
      model: null,
      team: [
        {
          img: "rachel.png",
          name: "Dr. Rachel Mhaville",
          role: "Paediatric Dentist and Orthodontist(Director)",
        },
        {
          img: "team_13.jpg",
          name: "Rani Dental Clinic",
          role: "Staff",
        },
      ],
    };
  },
};
</script>

<style scoped>
.welcome_text {
  font-family: "Maven Pro";
  font-size: 18px;
  margin-left: 5%;
  line-height: 35px;
  font-weight: 400;
  text-align: justify;
}
.welcome_text_mobile {
  font-family: "Maven Pro";
  font-size: 18px;
  line-height: 35px;
  font-weight: 400;
  text-align: justify;
}
</style>
