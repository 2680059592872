import axios from "axios";

const state = {};
const getters = {};
const actions = {
  sendFeedback({ commit }, message) {
    commit("loading", true);
    let formData = new FormData();
    formData.append("name", message.name);
    formData.append("email", message.email);
    formData.append("message", message.message);
    formData.append("service_id", process.env.VUE_APP_EMAIL_SERVICE_ID);
    formData.append("template_id", process.env.VUE_APP_EMAIL_TEMPLATE_ID);
    formData.append("user_id", process.env.VUE_APP_PUBLIC_KEY);
    axios
      .post("https://api.emailjs.com/api/v1.0/email/send-form", formData)
      .then((response) => {
        alert("Message Sent");
        console.log(response.data);
      })
      .catch((error) => {
        if (error) console.log(error.message);
        else console.log(error);
        alert("Failed to send message");
      })
      .finally(() => commit("loading", false));
  },
};
const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
