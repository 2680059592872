import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grey lighten-5"},[_c('div',{staticStyle:{"text-align":"center"}},[_c('h1',[_vm._v("Features")]),_c(VIcon,{attrs:{"x-large":""}},[_vm._v("mdi-minus")])],1),(!_vm.$vuetify.breakpoint.mobile)?_c(VRow,{staticClass:"mx-auto mt-10",attrs:{"id":"offer"}},_vm._l((_vm.services),function(service,index){return _c(VCol,{key:index},[_c(VCard,{staticClass:"mx-auto",attrs:{"width":"250","flat":"","color":"transparent"}},[_c(VAvatar,{staticClass:"mb-9 mx-auto",attrs:{"color":"grey","size":"150"}},[_c(VImg,{staticClass:"mx-auto",attrs:{"src":require('@/assets/home/services/' + service.img),"width":"300"}})],1),_c(VCardText,{staticStyle:{"color":"black","font-size":"large","font-weight":"500"}},[_vm._v(_vm._s(service.title))]),_vm._l((service.types),function(type,index){return _c('p',{key:index,staticClass:"text"},[_vm._v(_vm._s(type)+" ")])})],2)],1)}),1):_vm._e(),(_vm.$vuetify.breakpoint.mobile)?_c('div',{attrs:{"id":"offer"}},_vm._l((_vm.services),function(service,index){return _c(VCard,{key:index,staticClass:"mx-auto mt-15",staticStyle:{"text-align":"center"},attrs:{"width":"90%","flat":"","color":"transparent"}},[_c(VAvatar,{staticClass:"mx-auto",attrs:{"color":"grey","size":"100"}},[_c(VImg,{staticClass:"mx-auto",attrs:{"src":require('@/assets/home/services/' + service.img),"width":"200"}})],1),_c('h3',{staticClass:"my-5",staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(service.title))]),_vm._l((service.types),function(type,index){return _c('p',{key:index,staticClass:"mx-auto",staticStyle:{"line-height":"10px"}},[_vm._v(_vm._s(type)+" ")])})],2)}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }